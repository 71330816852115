.contact-wrapper{
  height: calc(100vh - 5em);
  background-color: $dark;
  padding: 3em 18vw;
  h1{
    color: $snow;
    font-family: $secondary-font;
  }
  .contacts-box{
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 5rem;
    .contact{
      height: 5rem;
      width: 5rem;
      margin: 0 2em;
      svg{
        height: 100%;
        width: 100%;
        fill: $rajah;
      }
    }
  }
}