.home-wrapper{
  display: flex; 
  justify-content: center;
  align-items: center;
  font-family: $main-font;
  height: calc(100vh - 5em);
  background-image: url('../assets/images/ross_portrait.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: $dark;

  .hero-image{
    position: relative;
    width: 100%;
    height: calc(100vh - 5em);
    
    span:first-child{
      color: $blood-red;
    }

    h1{
      position: absolute;
      bottom: 20%;
      left: 18vw;
      color: $snow;
      font-size: 9em;
      line-height: 1em;
    }
  }
}
@media only screen and (max-width: 720px){
  .home-wrapper{
    .hero-image{
      h1{
        font-size: 4em;
      }
    }
  }
}

