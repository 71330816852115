@import url('https://fonts.googleapis.com/css?family=Montserrat:800|Poppins:100,200,300,700&display=swap');

//COLORS
$dark: #23262B;
$hotpink: #EF6461;
$ocean: #124E78;
$snow: #FFFCF9;
$dark-grey: #515052;
$blood-red: #DB162F;
$jelly: #DB504A;
$white: #FFF;
$black: #000;
$rajah: #F7B267;
$turtle: #0DAB76;

//FONTS
$main-font: 'Poppins', sans-serif;
$secondary-font: 'Montserrat', sans-serif;