@import 'variables';
@import 'home';
@import 'about';
@import 'contact';
@import 'graphics';
@import 'tech';
@import 'music';
@import 'mixins';

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}   

html{
  font-size: 16px;  
  height: 100%;
  background-color: $dark;
}

ul{
  list-style-type: none;
}

a{
  text-decoration: none;
}

h1{
  font-size: 3em;
}

h2{
  font-size: 2em;
}

.app-wrapper{
  height: 100%;
  width: auto;
  overflow-x: hidden;
  overflow-y: hidden;
}
.content-wrapper{
  height: calc(100vh - 5em);

  .side-menu-container{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #0A0C0D;
    opacity: .95;
    z-index: 1;
    color: #053915
  }

  @include menu-links;

  .open{
    width: 20em;
    overflow: hidden;
    transition: .5s ease-in-out;
  }

  .closed{
    width: 0;
    overflow: hidden;
    transition: .5s ease-in-out;
  }
}

/* ROUTE TRANSITIONS */

.transition-group {
  position: relative;
  width: 100%;
  .route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}
.fade-enter {
  opacity: 0;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}
.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-out;
}

/* MEDIA QUERIES */


@media only screen and (max-width: 1024px){
  .home-wrapper{
    background-image: url('../assets/images/ross_portrait_crop.png')
  }
  .about-wrapper{
    .art-face{
      height: 80%;
      bottom: -3vh;
    }
  }
}
@media only screen and (max-width: 768px){
  .home-wrapper{
    background-image: url('../assets/images/ross_portrait_crop.png')
  }
  .about-wrapper{
    .art-face{
      height: 78%;
      bottom: -3vh;
    }
  }
}
@media only screen and (max-width: 450px){
  .home-wrapper{
    background-image: url('../assets/images/ross_portrait_crop.png')
  }
  .about-wrapper{
    .art-face{
      height: 65%;
      bottom: -3vh;
    }
  }
}