@import url(https://fonts.googleapis.com/css?family=Montserrat:800|Poppins:100,200,300,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800|Poppins:100,200,300,700&display=swap);
.home-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  height: calc(100vh - 5em);
  background-image: url(/static/media/ross_portrait.4978ddf8.png);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #23262B; }
  .home-wrapper .hero-image {
    position: relative;
    width: 100%;
    height: calc(100vh - 5em); }
    .home-wrapper .hero-image span:first-child {
      color: #DB162F; }
    .home-wrapper .hero-image h1 {
      position: absolute;
      bottom: 20%;
      left: 18vw;
      color: #FFFCF9;
      font-size: 9em;
      line-height: 1em; }

@media only screen and (max-width: 720px) {
  .home-wrapper .hero-image h1 {
    font-size: 4em; } }

.about-wrapper {
  height: calc(100vh - 5em);
  background-color: #DB504A;
  padding: 3em 18vw;
  display: flex; }
  .about-wrapper .art-face {
    height: 100%;
    width: auto;
    position: absolute;
    bottom: -4em;
    right: 0; }
  .about-wrapper .about-bio {
    max-width: 80%;
    display: flex;
    flex-direction: column; }
    .about-wrapper .about-bio h1 {
      margin-bottom: 2rem;
      font-family: "Montserrat", sans-serif;
      color: #FFFCF9; }
    .about-wrapper .about-bio p {
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      line-height: 2.3em;
      font-size: 1.1em;
      color: #23262B; }

.contact-wrapper {
  height: calc(100vh - 5em);
  background-color: #23262B;
  padding: 3em 18vw; }
  .contact-wrapper h1 {
    color: #FFFCF9;
    font-family: "Montserrat", sans-serif; }
  .contact-wrapper .contacts-box {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 5rem; }
    .contact-wrapper .contacts-box .contact {
      height: 5rem;
      width: 5rem;
      margin: 0 2em; }
      .contact-wrapper .contacts-box .contact svg {
        height: 100%;
        width: 100%;
        fill: #F7B267; }

.graphics-page-container {
  background-color: #124E78;
  height: calc(100vh - 5em);
  padding: 3em 18vw; }
  .graphics-page-container h1 {
    font-family: "Montserrat", sans-serif;
    color: #FFFCF9; }

.tech-page-container {
  background-color: #F7B267;
  background-image: url(/static/media/code_bg_light.39bd8339.svg);
  background-repeat: no-repeat;
  background-position: bottom;
  height: calc(100vh - 5em);
  padding: 3em 18vw; }
  .tech-page-container h1 {
    font-family: "Montserrat", sans-serif;
    color: #FFFCF9; }

.music-page-container {
  background-color: #0DAB76;
  height: calc(100vh - 5em);
  padding: 3em 18vw; }
  .music-page-container h1 {
    font-family: "Montserrat", sans-serif;
    color: #FFFCF9; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

html {
  font-size: 16px;
  height: 100%;
  background-color: #23262B; }

ul {
  list-style-type: none; }

a {
  text-decoration: none; }

h1 {
  font-size: 3em; }

h2 {
  font-size: 2em; }

.app-wrapper {
  height: 100%;
  width: auto;
  overflow-x: hidden;
  overflow-y: hidden; }

.content-wrapper {
  height: calc(100vh - 5em); }
  .content-wrapper .side-menu-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #0A0C0D;
    opacity: .95;
    z-index: 1;
    color: #053915; }
  .content-wrapper li {
    list-style-type: none;
    margin: 1em 2em;
    font-size: 1.4em; }
  .content-wrapper a {
    text-decoration: none;
    color: #FFFCF9;
    font-size: 1.2rem;
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    margin-right: 2em;
    position: relative; }
  .content-wrapper a:after {
    content: '';
    position: absolute;
    height: .1em;
    bottom: -.3em;
    margin: 0 auto;
    left: 0;
    width: 0;
    background-color: #FFFCF9;
    -webkit-transition: 2s ease-in-out;
    transition: 2s ease-in-out; }
  .content-wrapper a:not(.activeLink):hover:after {
    width: 100%;
    background-color: #FFFCF9;
    -webkit-transition: .5s;
    transition: .5s; }
  .content-wrapper a.activeLink:after {
    content: '';
    position: absolute;
    height: .1em;
    bottom: -.3em;
    margin: 0 auto;
    left: 0;
    width: 0;
    -webkit-transition: 2s ease-in-out;
    transition: 2s ease-in-out;
    width: 100%;
    background-color: #DB162F;
    -webkit-transition: .5s;
    transition: .5s; }
  .content-wrapper .open {
    width: 20em;
    overflow: hidden;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out; }
  .content-wrapper .closed {
    width: 0;
    overflow: hidden;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out; }

/* ROUTE TRANSITIONS */
.transition-group {
  position: relative;
  width: 100%; }
  .transition-group .route-section {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0; }

.fade-enter {
  opacity: 0; }

.fade-enter.fade-enter-active {
  opacity: 1;
  -webkit-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in; }

.fade-exit {
  opacity: 1; }

.fade-exit.fade-exit-active {
  opacity: 0;
  -webkit-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out; }

/* MEDIA QUERIES */
@media only screen and (max-width: 1024px) {
  .home-wrapper {
    background-image: url(/static/media/ross_portrait_crop.272497c1.png); }
  .about-wrapper .art-face {
    height: 80%;
    bottom: -3vh; } }

@media only screen and (max-width: 768px) {
  .home-wrapper {
    background-image: url(/static/media/ross_portrait_crop.272497c1.png); }
  .about-wrapper .art-face {
    height: 78%;
    bottom: -3vh; } }

@media only screen and (max-width: 450px) {
  .home-wrapper {
    background-image: url(/static/media/ross_portrait_crop.272497c1.png); }
  .about-wrapper .art-face {
    height: 65%;
    bottom: -3vh; } }

.Header_wrapper__2aNy3 {
  height: 5rem;
  width: 100%;
  background-color: #23262B;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18vw;
  position: relative; }
  .Header_wrapper__2aNy3 .Header_menuIconWrap__3dduw {
    height: 2em;
    width: 2em;
    position: absolute;
    left: 3em;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    z-index: 3; }
  .Header_wrapper__2aNy3 .Header_openMenuShift__3mKgx {
    left: 17em;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out; }
  .Header_wrapper__2aNy3 .Header_mainLinks__2R1Db {
    display: flex;
    justify-content: center;
    align-items: center; }
    .Header_wrapper__2aNy3 .Header_mainLinks__2R1Db li {
      list-style-type: none;
      margin: 1em 2em;
      font-size: 1.4em; }
    .Header_wrapper__2aNy3 .Header_mainLinks__2R1Db a {
      text-decoration: none;
      color: #FFFCF9;
      font-size: 1.2rem;
      font-family: "Poppins", sans-serif;
      font-weight: 100;
      margin-right: 2em;
      position: relative; }
    .Header_wrapper__2aNy3 .Header_mainLinks__2R1Db a:after {
      content: '';
      position: absolute;
      height: .1em;
      bottom: -.3em;
      margin: 0 auto;
      left: 0;
      width: 0;
      background-color: #FFFCF9;
      -webkit-transition: 2s ease-in-out;
      transition: 2s ease-in-out; }
    .Header_wrapper__2aNy3 .Header_mainLinks__2R1Db a:not(.Header_activeLink__3QFlG):hover:after {
      width: 100%;
      background-color: #FFFCF9;
      -webkit-transition: .5s;
      transition: .5s; }
    .Header_wrapper__2aNy3 .Header_mainLinks__2R1Db a.Header_activeLink__3QFlG:after {
      content: '';
      position: absolute;
      height: .1em;
      bottom: -.3em;
      margin: 0 auto;
      left: 0;
      width: 0;
      -webkit-transition: 2s ease-in-out;
      transition: 2s ease-in-out;
      width: 100%;
      background-color: #DB162F;
      -webkit-transition: .5s;
      transition: .5s; }
  .Header_wrapper__2aNy3 .Header_mainName__1-Oed {
    color: #FFFCF9;
    font-family: "Poppins", sans-serif;
    display: none; }

@media only screen and (max-width: 720px) {
  .Header_wrapper__2aNy3 {
    justify-content: flex-end; }
    .Header_wrapper__2aNy3 .Header_mainLinks__2R1Db {
      display: none; }
    .Header_wrapper__2aNy3 .Header_mainName__1-Oed {
      display: unset; } }

.BurgerMenu_burger-menu__34heK {
  height: 2em;
  width: 2em; }
  .BurgerMenu_burger-menu__34heK svg {
    height: 100%;
    width: 100%;
    fill: red;
    stroke: red; }


