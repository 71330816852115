@import 'variables';

.about-wrapper{
  height: calc(100vh - 5em);
  background-color: $jelly;
  padding: 3em 18vw;
  display: flex;
  .art-face{
    height: 100%;
    width: auto;
    position: absolute;
    bottom: -4em;
    right: 0;
  }
  .about-bio{
    max-width: 80%;
    display: flex;
    flex-direction: column;
    h1{
      margin-bottom: 2rem;
      font-family: $secondary-font;
      color: $snow;
    }
    p{
      font-family: $main-font;
      font-weight: 300;
      line-height: 2.3em;
      font-size: 1.1em;
      color: $dark;
    }
  }
}