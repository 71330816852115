@import '../../../style_sheets/variables';
@import '../../../style_sheets/mixins';

.wrapper{
  height: 5rem;
  width: 100%;
  background-color: $dark;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18vw;
  position: relative;

  .menuIconWrap{
    height: 2em;
    width: 2em;
    position: absolute;
    left: 3em;
    transition: .5s ease-in-out;
    z-index: 3;
  }
  
  .openMenuShift{
    left: 17em;
    transition: .5s ease-in-out;
  }
  
  .mainLinks{    
    display: flex;
    justify-content: center;
    align-items: center;
    @include menu-links;
  }

  .mainName{
    color: $snow;
    font-family: $main-font;
    display: none;
  }
}
@media only screen and (max-width: 720px){
  .wrapper{
    justify-content: flex-end;
    .mainLinks{
      display: none;
    }
    .mainName{
      display: unset;
    }
  }
}